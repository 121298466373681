.videoCpInfoFooter {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 24px;
    min-height: 232px;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0 0 0 / 0%) 0%, #000 100%);
    width: 100%;
    box-sizing: border-box;

    >*:not(:last-child) {
        margin-bottom: 12px;
    }

    .nameInfo {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .name {
            display: flex;
            gap: 8px;

            .nickname {
                color: #fafafa;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }

            .unSubscribe {
                border-radius: 12px;
                border: 1px solid #fafafa;
                color: #fafafa;
                font-size: 12px;
                font-weight: 700;
                line-height: 22px;
                padding: 0 8px;
            }

            .subscribe {
                color: #fafafa;
                display: flex;
                height: 24px;
                padding: 0 8px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 12px;
                background: #dc2626;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
            }
        }

        .userId {
            color: #d4d4d4;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .content {
        color: #d4d4d4;
        font-size: 16px;
        line-height: 24px;
    }

    .tags {
        display: flex;
        gap: 4px;

        .tag {
            border-radius: 16px;
            background: #D4D4D4;
            color: #262626;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            padding: 4px 8px;
        }
    }

    .mediaInfo {
        display: flex;
        gap: 8px;
        align-items: center;
        color: #a3a3a3;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;

        .info {
            display: flex;
            align-items: center;
            gap: 2px;
        }
    }
}