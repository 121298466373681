.videoSideMenu {
    position: absolute;
    top: 170px;
    right: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    z-index: 3;

    .avatarBtn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .streaming {
            position: absolute;
        }

        .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .subscribeIcon {
            position: absolute;
            bottom: -4px;
            left: 16px;
        }
    }

    .hideView {
        opacity: 0;
        z-index: -1;
    }

    .like {
        display: flex;
        flex-direction: column;
        align-items: center;

        .likeCount {
            color: #fff;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .icon {
        border-radius: 90px;
        background: rgba(0 0 0 / 40%);
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}